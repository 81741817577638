@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body,
#root {
  min-width: 100%;
}

@font-face {
  font-family: "kumoregular";
  src: local("Kumo-Regular"),
    url("./fonts/Kumo-Regular.ttf") format("truetype");
}

hr {
  margin-top: 15px;
  margin-bottom: 15px;
}

body {
  margin: 0;
  font-family: "kumoregular",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.carousel .control-dots .dot {
  background: #85dad4 !important;
}

.carousel .control-dots {
  position: relative !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
